import { useEffect, useState } from "react";
import "./App.css";
import { MessageViewerList } from "./component/MessageViewerList";
import { MessageViewerTable } from "./component/MessageViewerTable";

import { FiAperture, FiList, FiRefreshCw, FiTablet } from "react-icons/fi";
import { useAtom } from "jotai";
import { messageId, viewModeAtom } from "./atoms";
import { RESET } from "jotai/utils";
import { EmailMessage } from "./interfaces/EmailMessage";

function App() {
  const [error, setError] = useState(null as any);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([[], 0] as [EmailMessage[], number]);
  const [reload, tryReload] = useState(new Date());

  const [viewMode, setViewMode] = useAtom(viewModeAtom);
  const [selectedMessage, selectMessage] = useAtom(messageId);

  function reloadMessages() {
    if(selectedMessage) {
      selectMessage(RESET);
    }
    tryReload(new Date());
  }

  useEffect(() => {
    setIsLoaded(false);
    setError({});
    fetch("/api/search/")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [reload]);

  return (
    <div className="app">
      <div className="header">
        <h1>MailTrap</h1>

        <span className={(isLoaded && !error?.message) ? 'message-stat' : 'message-stat hide'}>{items[1]} messages trapped!</span>
        
        <span>
          {viewMode === "table" ? (
            <FiList
              onClick={() => {
                setViewMode("list");
              }}
            />
          ) : (
            <FiTablet
              onClick={() => {
                setViewMode("table");
              }}
            />
          )}
          <FiRefreshCw
            onClick={reloadMessages}
          />
        </span>
      </div>
      {!isLoaded && (
        <div className="loading-message">
          <span className="spin" style={{ fontSize: "3em" }}>
            <FiAperture className="spin-reverse" />
            【=◈︿◈=】
            <FiAperture className="spin-reverse" />
          </span>
        </div>
      )}
      {isLoaded &&
        viewMode === "table" &&
        (error?.message || <MessageViewerTable messages={items[0]} />)}
      {isLoaded &&
        viewMode === "list" &&
        (error?.message || <MessageViewerList messages={items[0]} />)}
    </div>
  );
}

export default App;
