import { useAtom } from "jotai";
import { createRef, useEffect, useLayoutEffect, useState } from "react";
import { messageId } from "../atoms";
import { EmailMessage } from "../interfaces/EmailMessage";
import "./MessageViewerList.css";

export function MessageViewerList({ messages }: { messages: EmailMessage[] }) {
  const [selectedMessage, selectMessage] = useAtom(messageId);

  const refs = messages.reduce((acc, value) => {
    acc[value.id] = createRef<HTMLDivElement>();
    return acc;
  }, {} as any);
  useEffect(() => {
    if (selectedMessage) {
      setTimeout(() => {
        refs[selectedMessage]?.current?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 0);
    }
  });

  return (
    <div className="message-viewer">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="message-list">
          {messages.slice(0, 200).map((message) => (
            <div
              className="message"
              ref={refs[message.id]}
              aria-selected={message.id === selectedMessage}
              key={message.id}
              onClick={() => {
                selectMessage(message.id);
              }}
            >
              <span>From: {message.from}</span>
              <span>To: {message.to}</span>
              <span>Subject: {message.subject}</span>
              <span>ID: {message.id}</span>
              <span>Date: {new Date(message.date).toLocaleString()}</span>
            </div>
          ))}
        </div>
      </div>

      <div className="message-content" style={{ flex: "1" }}>
        {selectedMessage && (
          <>
            <iframe
              style={{ width: "100%", height: "100%" }}
              frameBorder={0}
              src={`/api/open/${selectedMessage}`}
            />
          </>
        )}

        {!selectedMessage && <>Choose a message from the list!</>}
      </div>
    </div>
  );
}
