import { useAtom } from "jotai";
import { useState } from "react";
import { messageId } from "../atoms";
import { EmailMessage } from "../interfaces/EmailMessage";
import './MessageViewerTable.css';

export function MessageViewerTable({ messages }: { messages: EmailMessage[] }) {
  const [selectedMessage, selectMessage] = useAtom(messageId);
  return (
    <div className="message-viewer">
        <div className="message-list">
          <table className="message-table">
            <thead>
              <tr>
                <td>From</td>
                <td>&rarr;</td>
                <td>To</td>
                <td>Subject</td>
              </tr>
              <tr className="search">
                <td>
                  <input type="text" placeholder="test@email.com" />
                </td>
                <td></td>
                <td>
                  <input type="text" placeholder="test@email.com" />
                </td>
                <td>
                  <input type="text" placeholder="Your test email" />
                </td>
              </tr>
            </thead>
            <tbody>
              {messages.slice(0, 200).map((message) => (
                <tr
                  className="message"
                  key={message.id}
                  onClick={() => {
                    selectMessage(message.id);
                  }}
                >
                  <td style={{ textAlign: "right" }} className="from">
                    {message.from}
                  </td>
                  <td>&rarr;</td>
                  <td>{message.to}</td>
                  <td>{message.subject}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div style={{ flex: "1" }}>
          {selectedMessage && (<>
            <iframe
              style={{ width: "100%", height: "100%" }}
              frameBorder={0}
              src={`/api/open/${selectedMessage}`}
            />
            </>
          )}
        </div>
    </div>
  );
}
